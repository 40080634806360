<template>
    <div class="bg-[#F2F8F7] w-full h-full flex flex-grow">
      <div class="h-[calc(100%-58px)] border-t-[0.24px] overflow-y-auto flex flex-grow">
        <section class="w-full flex items-start justify-center py-10">
          <section class="w-[820px] bg-white page-shadow overflow-y-visible p-10">
            <h1 class="text-3xl pb-3">{{ pagesStore.selectedPage.title }}</h1>
            <Draggable v-model="pageContentsStore.pageContents" class="flex flex-col gap-1" @end="handleDragEnd" item-key="id">
              <template #item="{element, index}">
                <component
                    :is="pageComponentsMap[element.type]"
                    :key="index"
                    :data="element.content"
                    :id="element.id"
                    @edit-block="editElement(element)"
                    @blockFocused="setActiveBlock(element)"
                />
              </template>
            </Draggable>
            <DropdownButton :items="items" @update:selected="handleSelection" />
          </section>
        </section>
      </div>
      <div class="h-[calc(100%-58px)] border-t-[0.24px] bg-white w-[260px] max-w-[260px]">
        <div class="flex justify-start items-center gap-2.5 px-[20px] py-[7px] border-t-0 border-r-0 border-b-[0.4px] border-l-0 border-[#e6e6e6]">
          <p class="text-[14px] text-left text-[#9f9f9f]">Typography</p>
        </div>
        <section class="p-[15px] flex flex-col gap-[10px]">
          <div class="flex flex-col gap-[3px]">
            <p class="text-[12px] font-light text-left text-[#6d6d6d]">Font</p>
            <Dropdown v-model="selectedFont" :options="fonts" optionLabel="name" optionValue="code" aria-label="Font" placeholder="Font" class="w-full" />
          </div>
          <div class="flex gap-[5px] w-full">
            <div class="flex flex-col flex-1 gap-[3px]">
              <p class="text-[12px] font-light text-left text-[#6d6d6d]">Velicina</p>
              <Dropdown v-model="selectedFontSize" :options="fontSizes" optionLabel="name" optionValue="code" placeholder="Normal"/>
            </div>
            <div class="flex flex-col flex-1 gap-[3px]">
              <p class="text-[12px] font-light text-left text-[#6d6d6d]">Razmak</p>
              <Dropdown
                  v-model="selectedFontSpacing"
                  :options="spacingOptions"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Razmak"
              />
            </div>
          </div>
          <div class="flex gap-[5px] w-full">
            <div class="flex flex-col flex-1 gap-[3px]">
              <p class="text-[12px] font-light text-left text-[#6d6d6d]">Boja</p>
              <div class="flex justify-start items-center h-full pl-[5px] relative gap-[5px] rounded-[5px] border-[0.4px] border-[#e6e6e6]">
                <ColorPicker v-model="selectedFontColor"/>
                <p class="flex-grow-0 flex-shrink-0 text-[12px] text-left text-[#919191]">#{{selectedFontColor}}</p>
              </div>
            </div>
            <div class="flex flex-col flex-1 gap-[3px]">
              <p class="text-[12px] font-light text-left text-[#6d6d6d]">Prozirnost</p>
              <Dropdown v-model="selectedFontOpacity" :options="opacityOptions" optionLabel="name" optionValue="value" placeholder="Opa"/>
            </div>
          </div>
          <div class="flex flex-col gap-[3px] w-full">
            <p class="text-[12px] font-light text-left text-[#6d6d6d]">Poravnanje</p>
            <SelectButton
                v-model="selectedFontAlign"
                :options="alignmentOptions"
                optionLabel="value"
                optionValue="value"
                dataKey="value"
                aria-labelledby="custom"
                class="flex"
            >
              <template #option="slotProps">
                <i :class="slotProps.option.icon" class="!text-[12px]"></i>
              </template>
            </SelectButton>
          </div>
          <div class="flex flex-col gap-[3px] w-full">
            <p class="text-[12px] font-light text-left text-[#6d6d6d]">Argumenti</p>
            <SelectButton
                v-model="selectedTextType"
                :options="textTypeOptions"
                optionLabel="value"
                optionValue="value"
                dataKey="value"
                aria-labelledby="custom"
                class="flex"
            >
              <template #option="slotProps">
                <i :class="slotProps.option.icon" class="!text-[12px]"></i>
              </template>
            </SelectButton>
          </div>
        </section>
      </div>
   </div>
</template>

<script setup>
import Draggable from 'vuedraggable';
import {computed, onMounted, ref, watch} from "vue";
import DropdownButton from "@/components/input/DropdownButton.vue";
import TextBlock from "@/components/library/books/pages/TextBlock.vue";
import QuoteBlock from "@/components/library/books/pages/QuoteBlock.vue";
import ImageBlock from "@/components/library/books/pages/ImageBlock.vue";
import { useRoute } from 'vue-router';
import { useDebounceFn } from '@vueuse/core';
import {usePagesStore} from "@/store/pages/index.js";
import {useHandleSelection} from "@/components/app/dashboard/useHandleSelection.js";
import {usePageContentsStore} from "@/store/page-contents/index.js";
import Dropdown from 'primevue/dropdown';
import ColorPicker from 'primevue/colorpicker';
import SelectButton from 'primevue/selectbutton';
import { useTypographyControls } from '@/components/app/dashboard/composables/useTypographyControls';
const activeBlock = ref(null);
const value = ref(null);

import {
  fonts,
  fontSizes,
  spacingOptions,
  opacityOptions,
  alignmentOptions,
  textTypeOptions,
} from '@/components/app/dashboard/options/typographyOptions';

const route = useRoute();
const pagesStore = usePagesStore();
const pageContentsStore = usePageContentsStore();
const { handleSelection } = useHandleSelection(pageContentsStore);

const pageComponentsMap = {
  text: TextBlock,
  quote: QuoteBlock,
  image: ImageBlock,
};

const setActiveBlock = (element) => {
  activeBlock.value = element;
  console.log(activeBlock.value);
};

// Example of debouncing a method that fetches data based on a route parameter.
// This is useful if you expect rapid changes to the route params and want to limit
// the frequency of certain operations (like API calls).
const fetchData = useDebounceFn(async () => {
  console.log('Fetching data...');
  await pagesStore.fetchPage(route.params.pageId).then(async () => {
    await pageContentsStore.fetchPageContents(route.params.pageId)
  });
  console.log('Data fetched');
}, 200);

const editElement = useDebounceFn(async (element) => {
  await pageContentsStore.updatePageContent(element.id, element);
}, 200);

const {
  selectedFont,
  selectedFontSize,
  selectedFontSpacing,
  selectedFontOpacity,
  selectedFontAlign,
  selectedTextType,
  selectedFontColor,
} = useTypographyControls(activeBlock, editElement);

onMounted(() => {
  fetchData();
});

watch(() => route.params.pageId, () => {
  fetchData();
});

// Example items for DropdownButton
const items = ref([
  { icon: 'pr pr-text-block', text: 'Text', type: 'text', component: TextBlock },
  { icon: 'pr pr-quote-up-square', text: 'Quote', type: 'quote', component: QuoteBlock },
  { icon: 'pr pr-gallery', text: 'Image', type: 'image', component: ImageBlock },
]);
</script>

<style scoped>
.page-shadow {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  filter: drop-shadow(0 4px 3px rgba(0,0,0,0.1));
}

</style>
