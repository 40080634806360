import { ref, computed } from 'vue';
import { useDebounceFn } from '@vueuse/core';

export function useTypographyControls(activeBlock, editElement) {
    // Computed Properties
    const selectedFont = computed({
        get() {
            return activeBlock.value?.content.attributes.font || null;
        },
        set(value) {
            if (activeBlock.value) {
                activeBlock.value.content.attributes.font = value;
                updateActiveBlock();
            }
        },
    });

    const selectedFontSize = computed({
        get() {
            return activeBlock.value?.content.attributes.fontSize || null;
        },
        set(value) {
            if (activeBlock.value) {
                activeBlock.value.content.attributes.fontSize = value;
                updateActiveBlock();
            }
        },
    });

    const selectedFontSpacing = computed({
        get() {
            return activeBlock.value?.content.attributes.spacing || 'normal';
        },
        set(value) {
            if (activeBlock.value) {
                activeBlock.value.content.attributes.spacing = value;
                updateActiveBlock();
            }
        },
    });

    const selectedFontOpacity = computed({
        get() {
            return activeBlock.value?.content.attributes.opacity ?? 1.0;
        },
        set(value) {
            if (activeBlock.value) {
                activeBlock.value.content.attributes.opacity = value;
                updateActiveBlock();
            }
        },
    });

    const selectedFontAlign = computed({
        get() {
            return activeBlock.value?.content.attributes.align || 'left';
        },
        set(value) {
            if (activeBlock.value) {
                activeBlock.value.content.attributes.align = value;
                updateActiveBlock();
            }
        },
    });

    const selectedTextType = computed({
        get() {
            return activeBlock.value?.content.attributes.textType || 'text';
        },
        set(value) {
            if (activeBlock.value) {
                activeBlock.value.content.attributes.textType = value;
                updateActiveBlock();
            }
        },
    });

    const selectedFontColor = computed({
        get() {
            return activeBlock.value?.content.attributes.color || '#000000';
        },
        set(value) {
            if (activeBlock.value) {
                activeBlock.value.content.attributes.color = value;
                updateActiveBlock();
            }
        },
    });

    // Function to update the active block (debounced)
    const updateActiveBlock = useDebounceFn(() => {
        if (activeBlock.value) {
            editElement(activeBlock.value);
        }
    }, 300);

    return {
        selectedFont,
        selectedFontSize,
        selectedFontSpacing,
        selectedFontOpacity,
        selectedFontAlign,
        selectedTextType,
        selectedFontColor,
    };
}
